import { useState } from 'react';

type UseLocalStorageType = (
  key: string,
  initialValue: any,
) => [any, any];

function useLocalStorage(key: string, initialValue: any): any {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window == 'undefined') {
      return initialValue;
    }

    const item = window.localStorage.getItem(key);
    if (item == undefined) {
      return initialValue;
    }

    try {
      const parsedValue = JSON.parse(item);
      if (typeof parsedValue === 'object') {
        return parsedValue;
      } else {
        return item;
      }
    } catch (error) {
      // Ignoring the JSON parse error and return the current item
      return item;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      if (typeof window !== 'undefined') {
        const serializedValue =
          typeof valueToStore === 'object'
            ? JSON.stringify(valueToStore)
            : valueToStore;

        window.localStorage.setItem(key, serializedValue);
      }
    } catch (error) {}
  };

  return [storedValue, setValue] as const;
}

export { useLocalStorage as default };
