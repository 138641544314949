import authenticatedFetcher from '../authenticatedFetcher';
import { IAddressUpdate } from '@2ndmarket/types';
import { AppConstants } from '@2ndmarket/helpers';

const updateAddress = (
  data: IAddressUpdate,
  user_id: number | AppConstants.ME = AppConstants.ME
) => {
  const formData = new FormData();
  const postcode = data.postcode.replace(/\D/g, '');

  formData.append('country_id', '23');
  postcode && formData.append('postcode', postcode);
  data.state_id && formData.append('state_id', data.state_id.toString());
  data.city_id && formData.append('city_id', data.city_id.toString());
  data.district && formData.append('district', data.district);
  data.street && formData.append('street', data.street);
  data.number && formData.append('number', data.number);
  data.complement && formData.append('complement', data.complement);

  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/address`,
    method: 'PATCH',
    body: formData,
  });
};

export default updateAddress;