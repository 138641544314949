import { useEffect, useState } from 'react';

import { Icon, themes } from '@2ndmarket/components';
import { ICity, PaginationAmount } from '@2ndmarket/helpers';
import { useCities } from '@2ndmarket/services';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Loader from '@2ndmarket/components/src/Mui/Loader/Loader';

interface Props {
  cityOpen: boolean;
  selectedState: number;
  setCityOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCity: number;
  setSelectedCity: React.Dispatch<React.SetStateAction<ICity>>;
}

const CityDrawer: React.FC<Props> = ({
  cityOpen,
  selectedState,
  setCityOpen,
  selectedCity,
  setSelectedCity,
}) => {
  const startPage = 1;
  const initialPageSize = 20;
  const [citiesFilter, setCitiesFilter] = useState('');
  const citiesOrder = 'asc';
  const [page, setPage] = useState(startPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const {
    data: cities,
    loading,
    mutate,
  } = useCities(
    selectedState,
    citiesFilter,
    citiesOrder,
    page,
    pageSize,
  );

  useEffect(() => {
    if (cityOpen) {
      setCitiesFilter('');
      mutate();
    }
  }, [cityOpen, mutate]);

  let timer: any = null;
  function filter(event: React.ChangeEvent<HTMLInputElement>) {
    const keyword = event.target.value;
    clearTimeout(timer);

    if (keyword.length >= 3) {
      setPage(startPage);
      timer = setTimeout(() => {
        setCitiesFilter(keyword.toLowerCase());
      }, 500);
    } else {
      setCitiesFilter('');
    }
  }

  function changePage(selectedPage: number) {
    if (selectedPage < startPage) {
      return;
    }
    if (selectedPage > cities.lastPage) {
      return;
    }
    setPage(selectedPage);
  }

  function selectCity(item: ICity) {
    setSelectedCity(item);
    setCityOpen(false);
  }

  return (
    <Drawer
      open={cityOpen}
      anchor="right"
      role="presentation"
      onClose={() => setCityOpen(false)}
    >
      <Box p={4}>
        <Stack
          gap={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          pr={6}
        >
          <IconButton onClick={() => setCityOpen(false)}>
            <Icon
              size={15}
              name="close"
              color={themes.authentication.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
          >
            Selecione sua cidade
          </Typography>
        </Stack>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Busca"
          name="city-filter"
          sx={{ marginTop: 3 }}
          onChange={filter}
        />
        {loading ? (
          <Box
            sx={{
              width: '360px',
              height: 'calc(100svh - 260px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            <Loader loaderState={true} />
          </Box>
        ) : (
          <List
            sx={{
              width: '360px',
              maxHeight: 'calc(100svh - 260px)',
              overflowY: 'auto',
              flexWrap: 'wrap',
              maxWidth: 'calc(100vw - 64px)',
            }}
          >
            {cities.totalRecords == 0 ? (
              <ListItem
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Nenhuma cidade encontrada
              </ListItem>
            ) : (
              cities.results.map((item: ICity) => (
                <ListItem
                  key={item.id}
                  sx={{
                    padding: 0,
                    borderBottom:
                      '1px solid' +
                      themes.authentication.palette.grey[200],
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => selectCity(item)}
                    dense
                    sx={{ paddingX: 1 }}
                  >
                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                      <Checkbox
                        edge="start"
                        checked={item.id == selectedCity}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        )}
      </Box>
      <Box
        p={4}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 1,
          backgroundColor: 'background.neutral',
        }}
      >
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => changePage(page - 1)}
          disabled={cities.currentPage == startPage}
        >
          Anterior
        </Button>
        <TextField
          select
          fullWidth
          margin="dense"
          variant="outlined"
          defaultValue={initialPageSize}
          onChange={e => {
            setPageSize(Number(e.target.value)), mutate();
          }}
          sx={{
            m: 0,
            '.MuiInputBase-root': {
              height: '50px',
            },
          }}
        >
          {PaginationAmount.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          disabled={cities.lastPage == page}
          onClick={() => changePage(page + 1)}
        >
          Próximo
        </Button>
      </Box>
    </Drawer>
  );
};
export default CityDrawer;
