import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const getFileUrl = function (
  fileName: string,
  user_id: number | AppConstants.ME = AppConstants.ME) {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/files/temp-url?file_name=${fileName}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default getFileUrl;
