export const legalPerson = [
	{
		value: "COPERATIVA",
		label: "COPERATIVA",
	},
	{
		value: "CONSORCIO",
		label: "CONSÓRCIO",
	},
	{
		value: "EI",
		label: "EI",
	},
	{
		value: "EIRELI",
		label: "EIRELI",
	},
	{
		value: "EPP",
		label: "EPP",
	},
	{
		value: "LTDA",
		label: "LTDA",
	},
	{
		value: "ME",
		label: "ME",
	},
	{
		value: "MEI",
		label: "MEI",
	},
	{
		value: "SA",
		label: "SA",
	},
	{
		value: "SS",
		label: "SS",
	},
	{
		value: "EPP",
		label: "EPP",
	},
];
