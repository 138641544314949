import React, { useState, DragEvent, ChangeEvent } from 'react';
import { themes, Icon } from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';

interface FileProps {
  id?: string;
  label: string;
  onChange?: any;
  nameFile: string;
  error?: boolean | undefined;
  helperText?: string | undefined;
}

const CustomInputFile: React.FC<FileProps> = ({
  id,
  label,
  error,
  nameFile,
  onChange,
  helperText,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFile, setErrorFile] = useState(false);

  const [validExtensions] = useState([
    '.jpg',
    '.jpeg',
    '.png',
    '.pdf',
  ]);

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    setErrorFile(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = () => {
    setErrorFile(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setErrorFile(false);

    const files = event.dataTransfer.files;
    const file = files[0];

    const filePath = file.name as string;
    const fileExtension = filePath.substring(
      filePath.lastIndexOf('.'),
    );

    if (validExtensions.includes(fileExtension)) {
      handleFile(file as File);
    } else {
      setErrorFile(true);
      setSelectedFile(undefined);
      setErrorMessage('Formato de arquivo inválido...');
    }
  };

  const handleFile = (event: File) => {
    const file = event.name;
    if (file) {
      onChange(event);
      setErrorMessage('');
      setErrorFile(false);
      setSelectedFile(event);
    } else {
      setErrorFile(true);
      setSelectedFile(undefined);
      setErrorMessage('Formato de arquivo inválido');
    }
  };

  const handleFileChange = (event: ChangeEvent) => {
    const inputName = (event.target as HTMLInputElement).name;
    if (nameFile === inputName) {
      const file = (event.target as HTMLInputElement).files![0];
      if (file) {
        const filePath = file.name as string;
        const fileExtension = filePath.substring(
          filePath.lastIndexOf('.'),
        );

        if (validExtensions.includes(fileExtension)) {
          handleFile(file);
          setErrorMessage('');
          setErrorFile(false);
        } else {
          setErrorFile(true);
          setSelectedFile(undefined);
          setErrorMessage('Formato de arquivo inválido');
        }
      }
    }
  };

  const handleClose = () => {
    setErrorFile(true);
    const clearFile = new File([], '');
    if (selectedFile) {
      setSelectedFile({
        name: '',
        size: 0,
        type: '',
      } as File);

      if (nameFile == 'file_front') {
        setErrorMessage('Selecione a imagem de frente');
      } else if (nameFile == 'file_back') {
        setErrorMessage('Selecione a imagem de verso');
      }

      onChange(clearFile, nameFile, true);
    }
  };

  const renameFile = (name: string) => {
    const fileLength = 25;

    const fileName = name.split('.')[0];
    const fileExtension = name.split('.')[1];

    if (fileName.length <= fileLength) {
      return name;
    } else if (fileName.length > fileLength) {
      const remane = `${fileName.substring(
        0,
        fileLength,
      )}...${fileExtension}`;
      return remane;
    }
  };

  const FormControlInputFile = styled(FormControl)({
    marginTop: 0,
    height: '56px',
    display: 'flex',
    borderRadius: 3,
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'row',
    border: `1px dashed ${themes.authentication.palette.grey[300]}`,
    '.MuiFormLabel-root': {
      color: `${themes.authentication.palette.grey[300]}`,
      '&.MuiFormLabel-error': {
        color: `${themes.authentication.palette.error.main}`,
      },
      '&.MuiFormLabel-success': {
        color: `${themes.authentication.palette.primary.main}`,
      },
    },
    '&:hover': {
      border: `1px dashed ${themes.authentication.palette.primary.main}`,
      '.MuiFormLabel-root ': {
        color: `${themes.authentication.palette.primary.main}`,
      },
      '.Mui-error': {
        color: `${themes.authentication.palette.error.main}`,
      },
    },
    '&.MuiFormControl-error': {
      borderColor: `${themes.authentication.palette.error.main}`,
    },
    '&.MuiFormControl-success': {
      borderColor: `${themes.authentication.palette.primary.main}`,
    },
  });

  const IconButtonClose = styled(IconButton)({
    position: 'absolute',
    zIndex: 2,
    right: 9,
    '&.MuiIconButton-error': {
      color: themes.authentication.palette.error.main,
      '&:hover': {
        color: themes.authentication.palette.error.main,
        backgroundColor:
          themes.authentication.palette.error.contrastText,
        i: {
          color: themes.authentication.palette.error.main,
        },
      },
    },
    '&.MuiIconButton-success': {
      color: themes.authentication.palette.primary.main,
    },
  });

  return (
    <Stack spacing={0}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography
          marginBottom={1}
          variant="caption"
          component="strong"
          sx={{ color: themes.authentication.palette.grey[600] }}
        >
          {label}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          textAlign="right"
          sx={{
            marginBottom: '2px',
            color: themes.authentication.palette.grey[300],
          }}
        >
          ({validExtensions.join(', ').toUpperCase()})
        </Typography>
      </Box>
      <FormControlInputFile
        error={errorFile || error}
        className={
          errorFile || error
            ? 'MuiFormControl-error'
            : '' || selectedFile
            ? 'MuiFormControl-success'
            : ''
        }
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <FormLabel
          component="div"
          htmlFor={nameFile}
          error={errorFile || error}
          className={
            errorFile
              ? 'MuiFormLabel-error'
              : '' || selectedFile
              ? 'MuiFormLabel-success'
              : ''
          }
          sx={{
            paddingX: 2,
            gap: '14.5px',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon size={20} name="upload" />
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
          >
            {selectedFile ? (
              <>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body2" component="span">
                    Adicione ou arraste aqui
                  </Typography>
                  <Typography
                    component="p"
                    variant="body1"
                    color={
                      themes.authentication.palette.grey[700]
                    }
                  >
                    {renameFile(selectedFile.name)}
                  </Typography>
                </Box>
                <IconButtonClose
                  onClick={() => handleClose()}
                  className={
                    selectedFile ? 'MuiIconButton-success' : ''
                  }
                >
                  <Icon size={12} name="close" />
                </IconButtonClose>
              </>
            ) : (
              <>
                <Typography variant="body1" component="p">
                  Adicione ou arraste aqui
                </Typography>
                {!errorFile && (
                  <Typography variant="body1" component="p">
                    Adicionar
                  </Typography>
                )}
              </>
            )}

            {errorFile && (
              <IconButtonClose
                onClick={() => handleClose()}
                className={
                  errorFile ? 'MuiIconButton-error' : ''
                }
              >
                <Icon size={12} name="close" />
              </IconButtonClose>
            )}
          </Box>
        </FormLabel>
        <Input
          title=""
          type="file"
          id={nameFile}
          name={nameFile}
          className={nameFile}
          onChange={handleFileChange}
          inputProps={{
            multiple: false,
            capture: 'environment',
            accept: validExtensions,
          }}
          sx={{
            margin: 0,
            opacity: 0.01,
            width: '100%',
            position: 'absolute',
            '& .MuiInputBase-input': {
              '> input[type="file"]': {
                cursor: 'pointer !important',
              },
              cursor: 'pointer',
              height: '54px',
              padding: 0,
            },
          }}
        />
      </FormControlInputFile>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        {errorMessage || helperText ? (
          <Typography
            component="p"
            variant="body2"
            sx={{
              color: themes.authentication.palette.error.main,
            }}
          >
            {errorMessage || helperText}
          </Typography>
        ) : (
          <Typography component="p" variant="body2" />
        )}
      </Box>
    </Stack>
  );
};

export default CustomInputFile;
