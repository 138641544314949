import { DialogProps } from '@2ndmarket/types';
import { Icon, themes } from '@2ndmarket/components';
import { IconFormat } from '../../Icon/Icon';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ModalMessage: React.FC<DialogProps> = ({
  onClose,
  message,
  messageSecondary,
  title,
  titleIcon,
  error,
}) => {
  const defaultTitle = 'Falha de login';

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <Grid paddingX={2} paddingY={3}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle
            variant="h2"
            component="h2"
            color="text.primary"
            sx={{ paddingRight: 1 }}
          >
            {title ? title : defaultTitle}
          </DialogTitle>
          {titleIcon && (
            <Icon
              size={25}
              name={titleIcon}
              format={IconFormat.SVG}
            />
          )}
        </Box>
        {(error || message) && (
          <DialogContent>
            <Typography
              component="p"
              variant="body1"
              color="text.secondary"
            >
              {message}
            </Typography>
            {messageSecondary && (
              <Typography
                component="p"
                variant="body1"
                color="text.secondary"
                mt={3}
              >
                {messageSecondary}
              </Typography>
            )}
          </DialogContent>
        )}
        <DialogActions sx={{ paddingX: 3, mt: 5 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => onClose()}
            sx={{
              boxShadow: 'none',
              color: `${themes.authentication.palette.primary.main} !important`,
              backgroundColor: `${themes.authentication.palette.grey[200]} !important`,
            }}
          >
            Entendi
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ModalMessage;
