import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const userPhoneRequest = function (
  ddd: string,
  number: string,
  user_id: number | AppConstants.ME = AppConstants.ME
) {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/phone`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      ddi: '55',
      ddd: ddd,
      number: number,
    }),
  });
};

export default userPhoneRequest;
