import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { AddressEmpty, IAddress, IServiceResponse } from '@2ndmarket/types';

const useMyAddress = (
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IAddress> => {
  const key = {
    endpoint: `/v4/users/${user_id}/address`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IAddress, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? AddressEmpty,
    loading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export default useMyAddress;
