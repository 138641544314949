export interface IStates {
  totalRecords: number;
  pageSize: number;
  startRecord: number;
  lastPage: number;
  currentPage: number;
  results: [
    {
      id: number;
      name: string;
      initials: string;
      country: {
        name: string;
        nationality: string;
        id: number;
      };
    },
  ];
}

export const StatesEmpty: IStates = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      name: '',
      initials: '',
      country: {
        name: '',
        nationality: '',
        id: 0,
      },
    },
  ],
};

export interface IState {
  id: number;
  name: string;
  initials?: string;
}
