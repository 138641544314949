import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

interface Params {
  social_name?: string;
  birthdate?: string;
  fantasy_name?: string;
  legal_nature?: string;
  primary_cnae?: string;
  contact_name?: string;
  contact_cpf?: string;
  company_title?: string;
  status?: string;
}

const emptyableFields = ['social_name'];

const updateProfile = (
  params: Params,
  user_id: number | AppConstants.ME = AppConstants.ME,
) => {
  for (const key in params) {
    // @ts-ignore
    const value = params[key];
    if (value === '' && !emptyableFields.includes(key)) {
      // @ts-ignore
      delete params[key];
    }
  }

  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/profile`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  });
};

export default updateProfile;
