import authenticatedFetcher from '../authenticatedFetcher';

const getPostcodeInfo = function (postcode: string) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/postcode/${postcode.replace(/\D/g, '')}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default getPostcodeInfo;
