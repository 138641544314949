import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { IProfile, IServiceResponse, ProfileEmpty } from '@2ndmarket/types';

const useProfile = (
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IProfile> => {
  const key = {
    endpoint: `/v4/users/${user_id}/profile`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IProfile>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? ProfileEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useProfile;
