import { useEffect, useState } from 'react';

import { Icon, themes } from '@2ndmarket/components';
import { useStates } from '@2ndmarket/services';
import { IState } from '@2ndmarket/types';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface Props {
  stateOpen: boolean;
  setStateOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedState: number;
  setSelectedState: React.Dispatch<React.SetStateAction<IState>>;
  setChangeState: React.Dispatch<React.SetStateAction<boolean>>;
}

const StateDrawer: React.FC<Props> = ({
  stateOpen,
  setStateOpen,
  selectedState,
  setSelectedState,
  setChangeState,
}) => {
  const { data: states, loading, error } = useStates();
  const [filteredStates, setFilteredStates] = useState<IState[]>(
    [],
  );

  useEffect(() => {
    if (!loading && !error) {
      setFilteredStates(states.results);
    }
  }, [states, loading, error, setFilteredStates]);

  function filter(event: React.ChangeEvent<HTMLInputElement>) {
    const keyword = event.target.value;

    if (keyword != '') {
      const results = states.results.filter(item => {
        return item.name
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setFilteredStates(results);
    } else {
      setFilteredStates(states.results);
    }
  }

  function selectState(item: IState) {
    setChangeState(true);
    setSelectedState(item);
    setStateOpen(false);

    setTimeout(() => {
      setFilteredStates(states.results);
    }, 100);
  }

  return (
    <Drawer
      open={stateOpen}
      anchor="right"
      role="presentation"
      onClose={() => setStateOpen(false)}
    >
      <Box p={4}>
        <Stack
          gap={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          alignContent="center"
          pr={6}
        >
          <IconButton onClick={() => setStateOpen(false)}>
            <Icon
              size={15}
              name="close"
              color={themes.authentication.palette.grey[300]}
            />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            color="text.primary"
          >
            Selecione seu estado
          </Typography>
        </Stack>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Busca"
          name="state-filter"
          sx={{ marginTop: 3 }}
          onChange={filter}
        />
        <List
          sx={{
            width: '360px',
            flexWrap: 'wrap',
            maxWidth: 'calc(100vw - 64px)',
          }}
        >
          {filteredStates.length == 0 ? (
            <ListItem
              sx={{
                padding: 1,
                textAlign: 'center',
                display: 'block',
              }}
            >
              Nenhum estado encontrado
            </ListItem>
          ) : (
            filteredStates.map((item: IState) => (
              <ListItem
                key={item.id}
                sx={{
                  padding: 0,
                  borderBottom:
                    '1px solid' +
                    themes.authentication.palette.grey[200],
                }}
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => selectState(item)}
                  dense
                  sx={{ paddingX: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <Checkbox
                      edge="start"
                      checked={item.id == selectedState}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Drawer>
  );
};
export default StateDrawer;
