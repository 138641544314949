import authenticatedFetcher from '../authenticatedFetcher';

const getCityInfo = function (cityId: number) {
  return authenticatedFetcher({
    endpoint: `/v4/locale/cities/${cityId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default getCityInfo;
