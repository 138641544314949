import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants } from '@2ndmarket/helpers';
import { DocumentEmpty, IDocument, IServiceResponse } from '@2ndmarket/types';

const useDocument = (
  user_id: number | AppConstants.ME = AppConstants.ME
): IServiceResponse<IDocument> => {
  const key = {
    endpoint: `/v4/users/${user_id}/document`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<IDocument, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? DocumentEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useDocument;
