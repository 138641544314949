import authenticatedFetcher from '../authenticatedFetcher';
import { AppConstants, IDocument } from '@2ndmarket/helpers';

const updateDocument = (
  data: IDocument,
  user_id: number | AppConstants.ME = AppConstants.ME
) => {
  const formData = new FormData();

  if (data.type) formData.append('type', data.type);
  if (data.mothers_name)
    formData.append('mothers_name', data.mothers_name);
    if (data.expiration_date) formData.append('expiration_date', data.expiration_date);
  if (data.file_front && data.file_front instanceof File)
    formData.append('file_front', data.file_front);
  if (data.file_back && data.file_back instanceof File)
    formData.append('file_back', data.file_back);

  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/document`,
    method: 'PATCH',
    body: formData,
  });
};

export default updateDocument;
