import useSWR from 'swr';
import authenticatedFetcher from '../authenticatedFetcher';
import {
  CitiesEmpty,
  ICities,
  IServiceResponse,
} from '@2ndmarket/types';

const useCities = (
  stateId: string | number | null,
  filter: string = '',
  ordering: string = 'asc',
  page: number = 1,
  pageSize: number = 30,
): IServiceResponse<ICities> => {
  const key = {
    endpoint: `/v4/locale/cities?filter=${filter}&order=${ordering}&state_id=${stateId}&page=${page}&page_size=${pageSize}`,
  };

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const { data, error, mutate } = useSWR<ICities, Error>(
    key,
    authenticatedFetcher,
    options,
  );

  return {
    data: data ?? CitiesEmpty,
    loading: !error && !data,
    error,
    mutate,
  };
};

export default useCities;
