export const documentTypes = [
  {
    value: "rg",
    label: "Documento de Identidade",
  },
  {
    value: "cnh",
    label: "Carteira de Motorista",
  }
];
