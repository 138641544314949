export interface ICities {
  totalRecords: number,
  pageSize: number,
  startRecord: number,
  lastPage: number,
  currentPage: number,
  results: [
    {
      id: number,
      name: string,
      state: {
        id: number,
        name: string,
        initials: string,
        country: {
          name: string,
          nationality: string,
          id: number
        }
      }
    }
  ]
}

export const CitiesEmpty: ICities = {
  totalRecords: 0,
  pageSize: 0,
  startRecord: 0,
  lastPage: 0,
  currentPage: 0,
  results: [
    {
      id: 0,
      name: '',
      state: {
        id: 0,
        name: '',
        initials: '',
        country: {
          name: '',
          nationality: '',
          id: 0
        }
      }
    }
  ]
}