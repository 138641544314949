import { AppConstants } from '@2ndmarket/helpers';
import authenticatedFetcher from '../authenticatedFetcher';

const userUpdatePin = function (
  code: string,
  user_id: number | AppConstants.ME = AppConstants.ME
) {
  return authenticatedFetcher({
    endpoint: `/v4/users/${user_id}/pin`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      code: code,
    }),
  });
};

export default userUpdatePin;
