import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { MuiLoading } from '@2ndmarket/components';
import {
  OnboardingProgress,
  ReviewStep,
  Targets,
} from '@2ndmarket/helpers';
import { useMe } from '@2ndmarket/services';
import { IOnboardingBasic } from '@2ndmarket/types';

const Forwarder: React.FC = () => {
  const { data: me, loading, error } = useMe();
  const router = useRouter();

  const [loaderState, setLoaderState] = useState(true);

  useEffect(() => {
    const Routing = (data: string) => {
      if (data != router.pathname) {
        router.push(data);
      }
    };

    const OnboardingRedirect = (data: IOnboardingBasic) => {
      if (ReviewStep.APPROVED != data.profile_step) {
        Routing(Targets.ONBOARDING_DATA);
      } else if (ReviewStep.REFUSED == data.status) {
        Routing(Targets.REFUSED);
      } else if (
        ReviewStep.APPROVED != data.address_step &&
        ReviewStep.ANALYZING != data.address_step
      ) {
        Routing(Targets.ONBOARDING_ADDRESS);
      } else if (
        ReviewStep.APPROVED != data.document_step &&
        ReviewStep.RECEIVED != data.document_step
      ) {
        Routing(Targets.ONBOARDING_DOCUMENT);
      } else if (ReviewStep.APPROVED != data.phone_step) {
        Routing(Targets.ONBOARDING_PHONE);
      } else if (ReviewStep.APPROVED != data.pin_step) {
        Routing(Targets.ONBOARDING_PASSWORD);
      } else {
        Routing(Targets.ANALYSIS);
      }
      setLoaderState(false);
    };

    if (loading || error) {
      return;
    }

    if (OnboardingProgress.NEWUSER == me.onboarding_progress) {
      OnboardingRedirect(me.onboarding_basic);
    } else {
      if (router.pathname.startsWith(Targets.ONBOARDING)) {
        router.push(Targets.ROOT);
      } else {
        setLoaderState(false);
      }
    }
  }, [me, error, loading, router, setLoaderState]);

  return <MuiLoading loading={loaderState} />;
};

export default Forwarder;
